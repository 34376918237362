.company-details-header {
  margin: 56px 56px 15px 56px !important;
}

.company-details-container {
  margin:15px 0 15px 56px !important;
  text-align: left !important;
}

.users-list-main-container {
  padding:0 !important;
  margin:0 !important;
}

.company-details-grid-container {
  margin: 25px 56px 15px 56px !important;
}

.company-details-paper {
  height: 269px !important;
  width: 100% !important;
  text-align: left !important;
}

.company-details-input {
  margin-top: 28px !important;
  margin-bottom: 15px !important;
  margin-left: 32px !important;
  width: 60% !important;
}

.label {
  margin-top: 19px !important;
  margin-left: 32px !important;
  margin-bottom: 0px !important;
  width: 60% !important;
  font-size: 12px;
  color: #67676f;
}

.photo-container {
  min-width: 230px !important;
}

.submit-container {
    margin-top: 20px !important;
    margin-left: 32px !important;
}

.update-company-details {
  background-color: #3064f6 !important;
}

.photo-uploader-image {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 186px;
  height: 111px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  font-size: 5em;
  text-align: center !important;
  max-width: 100%;
  border-radius: 0px !important;

  &.has-image {
    background-color: white;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.no-image {
    background-color: #e9e9e9 !important;
    color: #828080 !important;
    padding-top: 15px !important;
  }
}

.camera-icon {
  font-size: 2.5rem !important;
}

.upload-photo {
  color: #3064f6 !important;
  font-size: small !important;
  margin-left: 28px !important;
  margin-top: 10px !important;
  box-shadow: 0px 3px 1px -2px transparent, 0px 2px 2px 0px transparent, 0px 1px 5px 0px transparent !important;
}

@media screen and (max-width: 1287px) {
  .company-details-paper {
    margin-top: 30px !important;
  }

  .company-details-input {
    width: 90% !important;
  }
}

@media screen and (max-width: 930px) {
  .company-details-paper {
    margin-top: 30px !important;
  }

  .company-details-input {
    width: 85% !important;
  }
}

@media screen and (max-width: 740px) {
  .company-details-paper {
    margin-top: 30px !important;
  }

  .company-details-input {
    width: 80% !important;
  }
}
