.users-list-item-container {
  background-color: #ffffff;
  padding:22.5px 0px !important;
  margin-bottom: 17px!important;
}
.users-list-item-margin-input {
  padding: 2.5px 20px !important;
  margin: -5px 5px !important;
}
.users-list-item-name {
  font-family: ObjectSans;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: 0.15px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.users-list-item-label {
  font-family: ObjectSans;
  font-size: 11px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.6 !important;
  letter-spacing: 1.5px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.users-list-item-email-address {
  font-family: ObjectSans;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.44 !important;
  letter-spacing: 0.15px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  word-wrap: break-word !important;
}

.users-list-item-role {
  font-family: ObjectSans;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.44 !important;
  letter-spacing: 0.15px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.users-list-item-edit-button {
  border-radius: 0 !important;
  padding: 10px !important;
  text-transform: none !important;
  border: solid 1px #010210 !important;
  font-family: ObjectSans;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.25px !important;
  text-align: center !important;
  color: #000000 !important;
  margin-right: 15px!important;
}
.users-list-item-edit-button-container {
  margin-right: 0px!important;
}

@media screen and (max-width: 1081px) {
  .users-list-item-edit-button-container {
    position: relative;
    margin-left: 82%!important;
    width: 100%!important;
  }
  .users-list-item-edit-button {
    float: right!important;
  }
}
@media screen and (max-width: 959px) {
  .users-list-item-name, .users-list-item-email-address {
    margin-bottom: 4px!important;
  }

  .users-list-item-edit-button-container {
    position: relative;
    margin-top: 10px!important;
    display: block !important;
    max-width: none!important;
    margin-left: 0!important;
  }
}

@media screen and (max-width: 845px) {
  .users-list-item-edit-button-container {
    position: relative;
  }
}

@media screen and (max-width: 540px) {
  .users-list-item-edit-button {
    float: left!important;
    display: block!important;
    margin-left: 24px!important;
  }
}

