.MuiDrawer-paper {
  overflow-y: hidden !important;
}

.company-logo {
  position: absolute;
  left: 70px;
  width: 100px;
  height: 50px;
}
