@import "colors";
.rides-list-item-full-name {
  display: block !important;
  width: 100% !important;
  padding:5px 0 !important;
}
.rides-list-item-only-name {
  font-size: 16px!important;
}

.rides-list-item-passenger-phone-number, .rides-list-item-driver-phone-number, .rides-list-vehicle-info {
  padding-left:8px !important;
}
.rides-list-user-icon {
  margin-top:-9px !important;
  margin-right:13px !important;
}
.rides-list-car-icon {
  margin-right:6px !important;
}
.rides-list-item-first-row-content {
  padding:10px 22.5px !important;
}

.rides-list-item-driver-name {
  padding-left:0px !important;
  font-size: 16px;
  font-weight:bold!important;
  color: rgba(0, 0, 0, 0.87)!important;
}

.rides-list-item-cancel-button {
  border-radius:0 !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.25px !important;
  border: solid 1.5px $secondary-black !important;
  margin-left:8px !important;
  margin-top:-4.5px !important;
}

.rides-list-item-receipt-button {
  border-radius:0 !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.25px !important;
  border: solid 1.5px $secondary-black !important;
  margin-left:8px !important;
  margin-top:-4.5px !important;
}


@media screen and (max-width: 991px) {
  .rides-list-progress-bar-component {
    width: 100%!important;
  }
}

@media screen and (max-width: 959px) {
  .rides-list-item-first-row-content {
    margin-bottom: -35px!important;
  }
  .rides-list-item-second-row-content {
    padding-left: 22.5px !important;
  }
}

@media screen and (max-width: 767px) {
  .rides-list-user-icon {
    display: inline-block !important;
  }
  .rides-list-item-only-name {
    display: inline-block !important;
  }
  .rides-list-item-passenger-phone-number {
    display: block !important;
    width: 100% !important;
    margin-left: 25px!important;
  }
  .rides-list-vehicle-info {
    display: block !important;
    width: 100% !important;
    padding-left: 0 !important;
  }
  .rides-list-item-cancel-button {
    margin-bottom: 10px!important;
  }
  .rides-list-item-receipt-button {
    margin-bottom: 10px!important;
  }

}

@media screen and (max-width: 479px) {
  .rides-list-item-cancel-button {
    width: 100%;
  }
  .rides-list-item-receipt-button {
    width: 100%;
  }
  .rides-list-item-driver-phone-number {
    width: 100%!important;
    display: block!important;
    padding-left: 0!important;
  }
  .rides-list-item-second-row-content {
    padding: 5px 22px!important;
  }
}
