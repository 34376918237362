@import "colors";
body {
  background-image: url('../images/logos/concierge-bg.jpeg');
  background-size: 100% 75%;
  background-repeat: repeat-x;
}
.login-form-title {
  font-size: 34px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.35 !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin: 20px 0px !important;
}
.login-form
{
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 482px;
}
.login-main-container {
  position: fixed;
  left: 75%;
  top: 50%;
}
.login-form-input {
  min-height: 82.5px !important;
}

.login-form-email-input {
  border-radius: 0 !important;
  padding: 24px 0 5px 0 !important;
  font-size: 35px !important;
}
.login-form-password-input {
  border-radius: 0 !important;
  padding: 22px 0 4px 0 !important;
}
.button-block{
  text-align: left !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.25px !important;
  background-color: $primary-blue !important;
  padding: 23px !important;
  width: 100%;
  border-radius: 0 !important;
}

.button-block span{
  position: absolute;
  left: 15px;
  float: left !important;
  text-align: left !important;
  width: auto !important;
  color: white;
}
.login-form-button-text{
  float: left !important;
  text-align: start !important;
}
.login-background
{
  justify-content: center;
  min-height: 30vh;
  padding:32px !important;
  width: 100%;
  height: auto;
  box-shadow: 40px 40px 70px -22px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 0 !important;
}

.login-form-remember-me-checkbox {
  color: $primary-blue !important;
  padding-left: 10px !important;
}
.login-form-extra-buttons {
  margin: 5px 7.5px !important;
  padding: 0 !important;
}
.login-form-forgot-password-text {
  float:right !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: 0.25px !important;
  text-align: right !important;
  color: $primary-blue !important;
  margin-top: -37.5px !important;
  margin-left: 20px !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}
.login-form-divider {
  margin-top:32px !important;
  background-color: $primary-gray !important;
}
.login-form-dont-have-account-grid {
  margin-top: 116px !important;
}
.login-form-dont-have-account-text {
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: 0.25px !important;
  color: #000000 !important;
}
.login-form-create-an-account-text {
  padding-left: 5px !important;
  color: $primary-blue !important;
  cursor: pointer;
}

.login-form-circular-progress {
  margin:10px !important;
  color: $primary-white !important;
}
.login-signup-container {
  margin-top: 20px !important;
}
.login-dont-have-account-text {
  letter-spacing: 0.4px;
  color: #72727a !important;
}
.login-form-sign-up-link {
  color: $primary-blue !important;
  cursor: pointer;
  text-decoration: underline;
}

//media-queries
@media screen and (max-width: 959px) {
  .login-form
  {
    position: absolute;
    top: 20%;
    right: 20%;
    bottom: 35%;
    width: 75% !important;
    height: auto;
  }
  .login-form-title {
    margin: 0px !important;
  }

}
@media screen and (max-width: 767px) {
  .login-form-divider {
    margin-bottom: -100px !important;
  }
  .login-form {
    bottom: 44%;
  }
}

@media screen and (max-width: 599px) {
  .login-form
  {
    position: absolute;
    bottom: 47%;
    top: 12.5%;
    right: 16.5%;
    width: 100%;
  }
 .login-form-forgot-password-text {
   margin-left: 100px !important;
   margin-top: -35px !important;
   width: 100%;
 }
  .login-form-checkbox-form-control {
    margin-left: -12px !important;
    text-align: center !important;
    width: 100%;
  }
  .login-form-divider {
   margin-bottom: -100px !important;
  }
  .login-form-title {
    margin: -15px 0px !important;
  }
  .concierge-logo {
    top: 20px !important;
  }
}

@media screen and (max-width: 520px) {

  .login-form-forgot-password-text {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    text-align: left !important;
    width: 100%;
  }
  .login-form-checkbox-form-control {
    text-align: left !important;
    width: 100%;
  }
  .concierge-logo {
    top: 20px !important;
  }
}

@media screen and (max-width: 425px) {
  .login-form
  {
    top: 17.5%;
  }
  .login-background
  {
    padding: 20px !important;
  }
  .login-form-title {
    padding-top: 10px !important;
  }
  .concierge-logo {
    top: 20px !important;
  }
}
@media screen and (max-width: 375px) {
  .login-form
  {
    top: 10%;
  }
  .login-background
  {
    padding: 20px !important;
  }
  .login-form-email-input {
    border-radius: 0 !important;
    padding: 15px 0 5px 0 !important;
    font-size: 35px !important;
  }
  .login-form-title {
    padding-top: 10px !important;
  }
  .concierge-logo {
    top: 20px !important;
  }
}

@media screen and (max-width: 350px) {
  .login-form-input {
    margin-left: 0px !important;
    width: 100%;
  }
  .login-form-input div {
    width: 100% !important;
  }
  .login-background {
    padding: 10px !important;
  }
  .login-form-title {
    padding-left: 10px !important;
  }
  .login-form-extra-buttons {
    padding-left: 10px !important;
  }
  .concierge-logo {
    top: 10px !important;
  }
}

@media screen and (max-width: 334px) {
  .login-form-input {
    margin-left: 0px !important;
    width: 80%;
  }
  .login-form-input div {
    width: 95% !important;
  }
  .login-background {
    padding: 10px 5% !important;
  }
  .login-form-title {
    padding-top: 10px !important;
    padding-left: 0 !important;
  }
  .login-form-extra-buttons {
    padding-left: 0px !important;
  }
  .concierge-logo {
    top: 10px !important;
  }
}
