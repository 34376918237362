/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.title {
  width: 442px;
  height: 46px;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}

.item-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.4px;
  color: #72727b;
}

.left-column {
  background-color: white;
  padding: 0 40px 40px 40px;
  overflow: auto;
}

.right-column {
  overflow: hidden;
}

/* Extra small devices */
@media only screen and (max-width: 600px) {
  .left-column, .right-column {
    min-height: calc((100vh - 48px)/2) !important;
    max-height: calc((100vh - 48px)/2) !important;
  }
}

/* Small devices */
@media only screen and (min-width: 601px) {
  .left-column, .right-column {
    min-height: calc((100vh - 64px)/2) !important;
    max-height: calc((100vh - 64px)/2) !important;
  }
}

/* Large devices */
@media only screen and (min-width: 1280px) {
  .left-column, .right-column {
    min-height: calc(100vh - 64px) !important;
    max-height: calc(100vh - 64px) !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

.schedule-ride {
  background-color:#3064f6 !important;
}

[data-placement="bottom-start"] {
  right: unset !important;
  z-index: 1000000 !important;
}

