/*@font-face {
  font-family: 'Fa solid 900';
  src: url('fonts/fa-solid-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}*/
.rides-list-action-bar-parent {
  position: relative;
  display: block;
  max-height: 100px;
  padding: 0;
  margin: 0;
  clear: none;
  width: 100%;
}
.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}
.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.rides-status-dropdown {
  position: relative;
  left: 25px;
}
.rides-search-bar {
  display: inline;
  width: 25%;
  float: left;
  clear: none;
}
.rides-list-search-bar-dropdown-toggle-status {
  padding: 3px 0;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #dedede;
  margin-top: 24px;
}
.rides-list-search-bar-dropdown-toggle-date {
  padding: 3px 0;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #dedede;
  margin-top: 24px;
}
.search-input {
  display: inline;
  float: right;
}

.rides-list-typed-value {
  position: relative;
  display: inline;
  width: auto;
  padding-right: 20px;
  padding-left: 0px;
  text-indent: 2.5px;
  float: left;
  line-height: 1.5;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #dedede;
  background-color: transparent;
  font-family: ObjectSans, serif;
  border-radius: 0 !important;
  margin-top: 13.5px;
}
.rides-list-typed-value:focus,.rides-list-typed-value:active {
  outline: none !important;
  box-shadow: none;
}
.rides-list-search-container {
  position: static;
  left: 0px;
  display: inline-block;
  width: auto;
  height: 100%;
  max-height: 100%;
  margin-top: 12px;
  padding-top: 0%;
  float: left;
  background-color: transparent;
  font-size: 16px;
}
#rides-list-status {
  background-color: transparent;
  border: 1px solid transparent;
}
#rides-list-date {
  background-color: transparent;
  border: 1px solid transparent;
}
.rides-list-date-container-alone {
  position: relative;
  display: inline-block;
  width: auto;
  min-width: 145px;
  max-width: 145px;
  background-color: transparent;
  margin-left:20px;
  font-size: 16px;
}

.rides-list-status-container {
  position: static;
  display: inline-block;
  width: 15%;
  min-width: 128px;
  max-width: 128px;
  margin-left: 25px;
  float: left;
  font-size: 16px;
}
.rides-list-date-container {
  position: static;
  left: 10%;
  display: inline-block;
  width: auto;
  min-width: 145px;
  max-width: 145px;
  margin-left: 25px;
  float: left;
  background-color: transparent;
  font-size: 16px;
}
.rides-list-action-bar-form {
  display: inline-block;
  width: 100%;
}
.rides-list-action-bar-parent-form {
  position: static;
  display: inline-block;
  width: 100%;
}
.text-block-8 {
  position: relative;
  left: 18px;
  top: 10px;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  display: inline-block;
  margin-top: 0px;
  padding-top: 0%;
  float: none;
  font-family: 'Fa solid 900', sans-serif;
}
.search-icon {
  position: static;
  display: inline;
  margin-bottom: 0px;
  float: right;
  font-family: "Fa solid 900", serif;
  content: "\f002";
  width: 26px;
}
.search-button {
  position: static;
  top: 3px;
  left: 0px;
  width: 38px !important;
  height: 38px !important;
  cursor: pointer;
  border-radius: 0 !important;
  background-color: #dcdcdc !important;
}
.rides-list-search-bar-angle-down {
  padding-top: 5px;
}
.icon-2 {
  padding-top: 5px;
}
.rides-list-submit-btn {
  display: none;
  padding: 7.5px 10px;
  float: right;
  border: 1.5px solid #000;
  background-color: #fff;
  color: #000;
  cursor: default;
  margin: 10px 0;
  position: relative;
  font-size: 16px;
}


@media screen and (max-width: 1080px) {
  .rides-list-action-bar-parent {
    float: left;
  }
  .rides-list-search-container {
    display: block;
    width: 100%;
    padding-right: 0px;
  }
  .rides-list-typed-value {
    display: inline;
    position: relative;
    float: left;
    width: 100%;
  }
  .search-button {
    width: auto;
    display: inline;
    position: relative;
    float: right;
    padding-left: 10px;
    top: -38px;
    border-radius: 0 !important;
  }
  .rides-list-status-container {
    position: relative !important;
    display: block !important;
    width: 100% !important;
    margin-top: -30px;
    margin-left: 0;
    max-width: none;
  }
  #rides-list-date {
    width: 100%;
  }
  .rides-list-date-container,.rides-list-date-container-alone {
    width: 100% !important;
    margin-left: 0;
    max-width: none;
    padding: 0;
  }
  .rides-list-date-container-alone {
    margin-top: -25px!important;
  }
  .rides-list-submit-btn {
    position: relative;
    top: 126px;
    display: none;
    width: auto;
    margin-top: -107px;
    padding-right: 20px;
    padding-left: 20px;
    float: right;
    clear: none;
  }
}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 479px) {
  .rides-list-submit-btn {
    float: left;
    width: 100%;
  }
}
