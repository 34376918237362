.ride-list-grid-container {
  margin-top:25px !important;
  margin:0px 56px !important;
}
.all-page-header {
  margin:56px 0 15px 56px !important;
}
.all-page-action-bar {
  marginTop:25px!important;
  margin:0px 56px!important;
}


@media screen and (max-width: 1080px) {
  .ride-list-grid-container {
    margin-top:125px !important;
  }
}

@media screen and (max-width: 767px) {
  .ride-list-grid-container {
    margin:130px 28px!important;
  }
  .all-page-header {
    margin:28px 0 15px 28px !important;
  }
  .all-page-action-bar {
    margin:0px 28px!important;
  }
}
