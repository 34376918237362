@font-face {
  font-family: ObjectSans;
  src: url('../fonts/ObjectSans-Regular.otf');
}

* {
  font-family: 'ObjectSans', sans-serif !important;
}

.concierge-logo {
  position: absolute;
  left: 46px;
  top: 40px;
}

.version-number-container {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.version-number-text {
  font-size: x-small !important;
  color:#676770;
  margin-top: 5px !important;
}
