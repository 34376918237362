@import "colors";

.add-user-modal-first-name, .add-user-modal-last-name{
  text-decoration: none!important;
  display: inline-block!important;
  margin-left:1% !important;
}
.add-user-modal-title {
  padding-bottom: 0 !important;
  font-weight: 900 !important;
}

.add-user-modal-content {
 padding-top: 0 !important;
  margin-top: 0px !important;
  max-width: 98% !important;
  overflow: hidden !important;
}

.add-user-modal-role-form-control {
  margin-left: 1% !important;
  width: 99% !important;
}
.add-user-modal-role {
  width: 100% !important;
  margin-left:0.25% !important;
  min-width: 120px !important;
}

.add-user-modal-email {
  margin-top: 0px !important;
  width: 99%!important;
  margin-left:1% !important;
}

.add-user-modal-cancel-button {
  text-transform: none !important;
  border-radius: 0 !important;
  border: solid 1px #010210 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  display: inline-block !important;
  letter-spacing: 0.25px !important;
  text-align: center !important;
  padding: 11.5px 17.5px !important;
  background-color: white !important;
  float: left !important;
}

.add-user-modal-submit-button {
  float: left !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: 0.25px !important;
  text-align: center !important;
  color: #ffffff !important;
  background-color: $primary-blue!important;
  text-transform: none !important;
  display: inline-block !important;
  margin-left: 8px !important;
  padding: 12.5px 17.5px !important;
}

.add-user-modal-actions {
  margin: 40px  0 17.5px 17.5px !important;
  width: 100%;
}

.add-user-modal-close-button {
  color: #dedede;
  position: absolute !important;
  right: 0 !important;
  top: 0px !important;
  border-radius: 0 !important;
}

.add-user-modal-close-button-container {
  position: absolute;
}



