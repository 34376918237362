.estimated-pickup {
  margin-left:auto;
  margin-right:auto;
  text-align:left;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #67676f;
}

.place-name {
  margin-left:auto;
  margin-right:auto;
  text-align:left;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #000000;
}

.br-size {
  line-height: 1%;
}

.gm-ui-hover-effect {
  display: none !important;
}

.no-close-button-on-info-windows .gm-ui-hover-effect { visibility: hidden; }

.gm-style-iw {
  max-height: 600px !important;
  max-width: 690px !important;
  overflow: auto !important;
  border-radius: 0 !important;
}

