.root {
  flex-grow: 1;
}

.list-scroll {
  height: calc(95vh - 200px);
  overflow-y: auto;
}

.paper {
  padding: 25px;
  text-align: center;
  color: #dcdcdc;
}

.rides-list-item {
  margin-bottom:0px !important;
}
.rides-list-label {
  color: rgba(0, 0, 0, 0.6);
  margin:15px 0px !important;
}
.rides-list-no-rides-found-message {
  margin:100px !important;
}
.rides-list-no-padding-and-margin {
  padding:0 !important;
  margin:0 !important;
  margin-bottom: 16px !important;
}
.rides-list-date {
  color: rgba(0, 0, 0, 0.6);
  margin:24px 0 !important;
}

.rides-list-canceled-popup-button {
  border-radius: 0 !important;
  text-transform: none !important;
}
