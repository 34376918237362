.root {
  flex-grow: 1;
}

.list-scroll {
  height: calc(95vh - 200px);
  overflow-y: auto;
}

.users-list-parent {
  padding: 0px !important;
}

.users-list-main-container {
  padding:0 !important;
  margin:0 !important;
}

.users-list-item {
  margin:0 !important;
}
.users-list-no-users-found-message {
  margin:100px !important;
}



@media screen and (max-width: 959px) {
  .users-list-parent {
    padding: 0 !important;
  }
  .users-list-item {
    margin:15px 0px !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 599px) {
  .Margin-input {
    margin:0px 20px !important;
    padding:1px 5px !important;
  }
}

@media screen and (max-width: 375px) {
  .Margin-input {
    margin:1px 10px !important;
    padding-left: 5px !important;
  }
}
