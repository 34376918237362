@import "colors";
.inkBar {
  background: white;
}

.boxSize {
  max-Width: 100%;
}

.MuiButtonBase-root {
  border-radius: 0 !important;
}

.edit-user-modal-cancel-button {
  text-transform: none !important;
  border-radius: 0 !important;
  border: solid 1px #010210 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  display: inline-block !important;
  letter-spacing: 0.25px !important;
  text-align: center !important;
  padding: 11.5px 17.5px !important;
  background-color: white !important;
  float: left !important;
}

.edit-user-modal-submit-button {
  float: left !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: 0.25px !important;
  text-align: center !important;
  color: #ffffff !important;
  background-color: $primary-blue!important;
  text-transform: none !important;
  display: inline-block !important;
  margin-left: 8px !important;
  padding: 12.5px 17.5px !important;
}
