body {
  background-color: #f6f6f6;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
a {
  color: #676770
}
a:hover {
  color: #676770;
  text-decoration: none;
}
.plus-icon {
  position: relative;
  float: left;
  text-align: left;
  color: white;
}
.add-icon {
  position:absolute;
  left:15px;
  top:10px;
}
.add-icon-closed {
  position:absolute;
  left:10px;
  top:10px;
}
.new-ride-btn, .new-ride-btn:focus {
  background-color:#3064f6 !important;
  text-transform: none !important;
  margin:15px !important;
  border-radius:0 !important;
  padding:10px !important;
  width:90% !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.25px !important;
  color: #ffffff !important;
}

.new-ride-btn span {
  color:white;
  font-size:15px;
  margin-left:-12px;
}
.side-bar-divider {
  margin: 6px 0;
  display:none;
}

.new-ride-closed-btn {
  padding:20px !important;
  margin:15px !important;
  border-radius:0 !important;
  background-color:#3064f6 !important;
  color:white !important;
}
.sidebar-item:first-child:active, .sidebar-item:first-child:focus {
  border-left: 4px solid #3064f6;
  background-color: rgba(41, 41, 41, 0.08);
}

.sidebar-item:first-child {
  border-left: 4px solid white;
}

.sidebar-divider {
  margin: 1px 0;
}

.sidebar {
  min-width: 239px;
  width: 12.5%;
  border: 0px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  min-height: 900px;
  max-height: 1200px;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.16px;
  color: #010210;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid white;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-item-icon {
  margin-right: 6px;
}

.sidebar-item-text {
  width: 100%;
  margin: 10px 30px;

}
.sidebar-item-text:first-child {
  width: 100%;
  margin: 10px 45px;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
  color: #171717;
  font-weight: bold;
}
.sidebar-footer {
  width:256px;
  border-top:1px solid #dedede;
  padding:0 20px;
}
.sidebar-footer-closed {
  width:256px;
  padding:0 20px;
}

.sidebar-footer-parent {
  display:block;
  position:fixed;
  width:256px;
  bottom:2px;
  left:0px;
}
.sidebar-footer-parent-closed {
  display:block;
  position:fixed;
  width:239px;
  bottom:2px;
  left:-5px;
}

.sidebar-question-icon-button {
  float:left!important;
  color: #565656!important;
}
.sidebar-question-icon-button-closed {
  margin-left:-4% !important;
  color: #565656 !important;
}
.sidebar-svg-icon-size {
  font-size: 24px!important;
}

.sidebar-help-center-text {
  position:absolute;
  font-size: 14px;
  top:63px;
  left:85px;
  color:#676770;
}

.sidebar-expand-icon-button {
  float:right!important;
  color: #565656!important;
}

/** Media Queries **/
@media screen and (max-width: 599px) {
  .new-ride-closed-btn {
    padding:20px !important;
    margin:15px 7px !important;
  }
  .sidebar-item-closed-content {
    margin-left: -5px;
  }
  .sidebar-question-icon-button-closed {
    margin-left:-7px !important;
  }
}
