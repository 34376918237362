@import "colors";
.users-page-header {
  margin:56px 56px 15px 56px !important;
}
.users-page-add-btn-container {
  position: relative;
  margin:56px 0 15px 175px !important;
}
.users-page-add-btn {
  position:absolute;
  bottom:0;
  right:0;
  background-color: $primary-blue !important;
  border-radius: 0 !important;
  float: right;
  padding: 16px !important;
  text-transform: none !important;
  font-size: 15px!important;
  font-weight: 500!important;
  font-stretch: normal!important;
  font-style: normal!important;
  line-height: 1.33!important;
  letter-spacing: 0.25px!important;
  text-align: center!important;
}

.users-list-grid-container {
  margin:25px 56px 15px 56px !important;
}


@media screen and (max-width: 540px) {
  .users-page-header {
    margin:25px 15px 0px 15px !important;
  }
  .users-page-h4 {
    display: block !important;
    width: 100%!important;
  }
  .users-page-add-btn {
    display: block!important;
    min-width: 110px!important;
    max-width: 110px!important;
    margin-top: 10px!important;
    float: left;
  }
  .users-list-grid-container {
    margin:15px !important;
  }
}

