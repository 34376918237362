@import "colors";
.signup-form
{
  position: absolute;
  bottom: 10%;
  right: 10%;
  max-width: 482px !important;
}

.signup-form-child-grid {
  margin: 0 auto !important;

}

.signup-form-first-row {
  margin-top: 10px !important;
}

.signup-form-input {
  border-radius: 0 !important;
  padding: 0 !important;
  font-size: 35px !important;
  min-height: 55px !important;
}

.signup-form-country-code div {
  margin-top: 0 !important;
  min-width: 75px;
}

.signup-form-country-code label {
  margin-top: -15px !important;
  color: #3b3b3b !important;
  opacity: 0.4;
  min-width: 100px;
}
.signup-form-checkbox-container {
  min-height: 55px !important;
}
.signup-form-policy-checkbox {
  margin-top: 3px !important;
}
.signup-form-policy-container {
  display: inline-block !important;
  margin-left: -20px !important;
}

.signup-form-underlined-privacy {
  color: $primary-blue !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.signup-form-extra-buttons {
  margin:-7.5px 7.5px 10px 5px !important;
  padding: 0 !important;
}

.signup-form-policy-checkbox-error-message {
  margin-top: -15px !important;
}

.signup-form-title {
  margin-top: 5px!important;
}
.signup-form-already-have-account-text {
  letter-spacing: 0.4px;
  color: #72727a !important;
}
.signup-form-log-in-link {
  color: $primary-blue !important;
  cursor: pointer;
  text-decoration: underline;
}

.signup-form-submit-button {
  text-align: left !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.25px !important;
  background-color: $primary-blue !important;
  width: 100%;
  padding: 23px!important;
  border-radius: 0 !important;
  color: white !important;
}
.signup-form-submit-button span {
    position: absolute;
    left: 15px;
    float: left !important;
    text-align: left !important;
    width: auto !important;
    color: white;
}

// breakpoints

@media screen and (max-width: 1440px) {
  .signup-form
  {
    position: absolute;
  }
}

@media screen and (max-width: 959px) {
  .signup-form
  {
    top: 20%;
  }
  .signup-form-background {
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 768px) {
  .signup-form
  {
    right: 0%;
    max-width: none !important;
    padding:0px 110px!important;
  }

}

@media screen and (max-width: 599px) {
  .signup-form {
    padding:0 50px!important;
    top: 7.5%;
  }
  .concierge-logo {
    top: 20px !important;
  }
}

@media screen and (max-width: 436px) {
  .signup-form
  {
    position: absolute;
    bottom: 15%;
    left: 0%;
    width: 100%!important;
    min-width: 0!important;
    padding: 15px !important;
  }
  .signup-form-child-grid {
    width: 100%!important;
  }
  .concierge-logo {
    top: 20px !important;
  }
}

@media screen and (max-width: 342px) {
  .signup-form {
    padding:0 7.5px!important;
    left: 0%;
  }
  .concierge-logo {
    top: 10px !important;
  }
}

@media screen and (max-width: 330px) {
  .signup-form-title {
    font-size: 27px !important;
  }
  .concierge-logo {
    top: 10px !important;
  }
}






