.MuiListItemAvatar-root {
  min-width: 76px;
}

.MuiAvatar-root {
  width: 60px;
  height: 60px;
}

.MuiInput-underline-error:after {
  border-bottom: 2px solid #f44336 !important;
}

#service-level .MuiListItem-gutters {
  padding-left: 0 !important;
}

#service-level .MuiListItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

#service-level-select-input {
  padding: 2px 0 2px !important;
}


